import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { capitalizeFirstLetter, dateFormat } from "../../services"
import './styles2.css'
import { projectName } from "../../firebase"
import { setMobileMenu } from "../../state/actions/mobileMenu"

function HomePage () {
    const collections = useSelector((state) => (state.collections.collections["index"]))
    const dispatch = useDispatch()
    console.log("collections",collections)
    return (
        <>
            <div class="menu-projects">
                <div class="menu-projects__wrap">
                    <div class="projects__heading">
                        <div class="heading-center">
                            <div class="projects-head__flex">
                            <h1 class="h-24 text-dark">{projectName}</h1>
                            </div>
                            <div class="update-flex">
                                <p>Last updated</p>
                            <div class="w-dyn-list">
                                <div role="list" class="w-dyn-items">
                                    <div role="listitem" class="w-dyn-item">
                                        <p>23.04.2023</p>
                                    </div>
                                </div>
                                {/* <div class="w-dyn-empty">
                                <div>No items found.</div>
                                </div> */}
                            </div>
                            </div>
                        </div>
                        <div onClick={()=> dispatch(setMobileMenu(true))} data-w-id="ecbbd13b-b482-7cdf-0270-fd4d2e7454e4" style={{opacity:1, display: "flex"}} class="hamburger-wrap _3-lines">
                            <div class="hamburger-line"></div>
                            <div class="hamburger-line middle"></div>
                            <div class="hamburger-line"></div>
                        </div>
                        </div>
                        <div class="border mt-16"></div>
                        <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs-home w-tabs">
                        <div class="menu-tabs">
                            <Link to={`/new-collection`}>
                                <button
                                    class="add-item-button w-button"
                                    >
                                    Add Collection (Daud don't press this button)
                                </button>
                            </Link>
                            {/* <a onClick={() => setTab(1)} data-w-tab="Tab 1" className={tab === 1 ? 'tab-switch w--current' : 'tab-switch'}>
                                <div class="centered ">
                                    <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.35" d="M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z" fill="currentColor"></path>
                                        <rect x="6" y="6" width="5" height="12" rx="1" fill="currentColor"></rect>
                                        <rect x="13" y="6" width="5" height="12" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </div>
                            </a>
                            <a onClick={() => setTab(2)} data-w-tab="Tab 2" className={tab === 2 ? 'tab-switch w--current' : 'tab-switch'}>
                                <div class="centered">
                                    <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.35" d="M20 14.62V8L14 2H7C5.343 2 4 3.343 4 5V19C4 20.657 5.343 22 7 22H15.668C16.492 22.914 17.673 23.5 19 23.5C21.485 23.5 23.5 21.485 23.5 19C23.5 16.86 22.002 15.076 20 14.62Z" fill="currentColor"></path>
                                        <path d="M14 6V2L20 8H16C14.895 8 14 7.105 14 6Z" fill="currentColor"></path>
                                        <path d="M12 13H8C7.447 13 7 12.552 7 12C7 11.448 7.447 11 8 11H12C12.553 11 13 11.448 13 12C13 12.552 12.553 13 12 13Z" fill="currentColor"></path>
                                        <path d="M12 17H8C7.447 17 7 16.552 7 16C7 15.448 7.447 15 8 15H12C12.553 15 13 15.448 13 16C13 16.552 12.553 17 12 17Z" fill="currentColor"></path>
                                        <path d="M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13Z" fill="currentColor"></path>
                                        <path d="M16 18C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16C15.4477 16 15 16.4477 15 17C15 17.5523 15.4477 18 16 18Z" fill="currentColor"></path>
                                        <path d="M19 14C16.24 14 14 16.24 14 19C14 21.76 16.24 24 19 24C21.76 24 24 21.76 24 19C24 16.24 21.76 14 19 14ZM19.43 22.14V22.54C19.43 22.8 19.23 23 18.98 23C18.72 23 18.52 22.8 18.52 22.54V22.22C18.14 22.18 17.82 22.07 17.57 21.92C17.26 21.75 17.18 21.34 17.38 21.05L17.4 21.02C17.57 20.77 17.9 20.67 18.17 20.81C18.33 20.89 18.53 20.96 18.76 20.96C19.27 20.96 19.36 20.52 19.36 20.33C19.36 19.3 17.3 19.65 17.3 17.68C17.3 16.78 17.8 16.08 18.56 15.83V15.46C18.56 15.2 18.77 15 19.02 15C19.27 15 19.48 15.2 19.48 15.46V15.73C19.77 15.76 20.01 15.83 20.2 15.91C20.55 16.07 20.67 16.51 20.44 16.83C20.27 17.07 19.95 17.19 19.67 17.07C19.56 17.01 19.42 16.98 19.26 16.98C19.11 16.98 18.65 17.04 18.65 17.62C18.65 18.6 20.73 18.34 20.73 20.23C20.73 21.11 20.28 21.88 19.43 22.14Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                            </a> */}
                        </div>
                        <div class="tabs-content-2 w-tab-content">
                            <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
                                <div class="filters">
                                    <div class="filters-wrap">
                                        <div class="no-m w-form">
                                            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" class="form-filter" data-wf-page-id="649d9a14bfbc7dd44a6ee735" data-wf-element-id="ecbbd13b-b482-7cdf-0270-fd4d2e7454f4">
                                                <div data-delay="0" data-hover="false" class="filter-dd w-dropdown">
                                                    <div class="no-p w-dropdown-toggle">
                                                        <div class="dd-text-flex">
                                                            <p class="p-13 text-light">Assignee</p>
                                                            <p class="p-13">All Assigness</p>
                                                        </div>
                                                        <div class="centered w-embed">
                                                            <svg width="9" height="10" viewbox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.5726 3.76602C7.55109 3.71483 7.5149 3.67116 7.46861 3.6405C7.42231 3.60984 7.36797 3.59358 7.31245 3.59375H1.68745C1.63192 3.59358 1.57759 3.60984 1.53129 3.6405C1.48499 3.67116 1.44881 3.71483 1.42729 3.76602C1.40703 3.81797 1.40189 3.87459 1.41247 3.92934C1.42304 3.98409 1.4489 4.03473 1.48706 4.07539L4.29956 6.88789C4.35332 6.93987 4.42517 6.96893 4.49995 6.96893C4.57473 6.96893 4.64658 6.93987 4.70034 6.88789L7.51284 4.07539C7.55099 4.03473 7.57685 3.98409 7.58743 3.92934C7.59801 3.87459 7.59287 3.81797 7.5726 3.76602Z" fill="currentColor"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <nav class="dd-list-filter w-dropdown-list">
                                                        <div class="assignees-form">
                                                            <label class="w-checkbox checkbox-field">
                                                                <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-button w--redirected-checked"></div><input type="checkbox" id="all-assigne" name="checkbox-3" data-name="Checkbox 3" checked="" style={{opacity:0,position:"absolute",zIndex:-1}}/><span class="w-form-label" for="checkbox-3">All Assigness</span>
                                                            </label>
                                                            <div class="w-dyn-list">
                                                                <div role="list" class="services-check-wrap w-dyn-items">
                                                                    <div role="listitem" class="w-dyn-item">
                                                                        <label class="w-checkbox assigne-checkbox">
                                                                            <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-button-assigne"></div><input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3" style={{opacity:0,position:"absolute",zIndex:-1}}/><span class="w-form-label" for="checkbox-3"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="w-dyn-empty">
                                                                    <div>No items found.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </nav>
                                                </div>  
                                                <div data-delay="0" data-hover="false" class="filter-dd w-dropdown">
                                                    <div class="no-p w-dropdown-toggle">
                                                        <div class="dd-text-flex">
                                                            <p class="p-13 text-light">Service</p>
                                                            <p class="p-13">All Services</p>
                                                        </div>
                                                        <div class="centered w-embed">
                                                            <svg width="9" height="10" viewbox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.5726 3.76602C7.55109 3.71483 7.5149 3.67116 7.46861 3.6405C7.42231 3.60984 7.36797 3.59358 7.31245 3.59375H1.68745C1.63192 3.59358 1.57759 3.60984 1.53129 3.6405C1.48499 3.67116 1.44881 3.71483 1.42729 3.76602C1.40703 3.81797 1.40189 3.87459 1.41247 3.92934C1.42304 3.98409 1.4489 4.03473 1.48706 4.07539L4.29956 6.88789C4.35332 6.93987 4.42517 6.96893 4.49995 6.96893C4.57473 6.96893 4.64658 6.93987 4.70034 6.88789L7.51284 4.07539C7.55099 4.03473 7.57685 3.98409 7.58743 3.92934C7.59801 3.87459 7.59287 3.81797 7.5726 3.76602Z" fill="currentColor"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <nav class="dd-list-filter w-dropdown-list">
                                                        <div class="services-form">
                                                            <label class="w-checkbox checkbox-field">
                                                                <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-button w--redirected-checked"></div>
                                                                <input type="checkbox" id="all-services" name="checkbox-2" data-name="Checkbox 2" checked="" style={{opacity:0,position:"absolute",zIndex:-1}}/>
                                                                <span fs-cmsfilter-element="reset" class="w-form-label" for="checkbox-2">All Services</span>
                                                            </label>
                                                            <div class="w-dyn-list">
                                                                <div role="list" class="services-check-wrap w-dyn-items">
                                                                    <div role="listitem" class="w-dyn-item">
                                                                        <label class="w-checkbox services-checkbox">
                                                                            <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-button"></div>
                                                                            <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" style={{opacity:0,position:"absolute",zIndex:-1}}/>
                                                                            <span class="w-form-label" for="checkbox"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="w-dyn-empty">
                                                                    <div>No items found.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </form>
                                            <div class="w-form-done">
                                                <div>Thank you! Your submission has been received!</div>
                                            </div>
                                            <div class="w-form-fail">
                                                <div>Oops! Something went wrong while submitting the form.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="projects-wrapper">
                                    <div class="projects-column">
                                        <div class="projects-count__wrap">
                                            <h2 class="h-16 text-dark">Databases</h2>
                                            <div class="count-box">
                                                <p class="upcoming-number">{collections.length}</p>
                                            </div>
                                        </div>
                                        <div class="scroll-wrap">
                                            <div class="projects-collection w-dyn-list">
                                                {collections?
                                                <div fs-cmsnest-element="list" role="list" class="collection-list filter-complex w-dyn-items">
                                                    {collections?.map((fieldName, index) => (                                        
                                                    <div role="listitem" key={index} class="upcoming w-dyn-item">
                                                        <Link to={`/${fieldName.collectionName}`}>
                                                        <a href="#" class="project w-inline-block">
                                                            <div class="project-heading">
                                                                {/* <div class="project-logo__wrap">
                                                                    <img loading="lazy" src="" alt=""/>
                                                                </div> */}
                                                                <div>
                                                                    <h3 class="h-16 text-dark mb-5"></h3>
                                                                    <div class="number-wrap">
                                                                        <p>{capitalizeFirstLetter(fieldName?.collectionName)}</p>
                                                                        <p></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fs-cmsnest-collection="categories" class="cateogires-wrap"></div>
                                                            <div class="assignees">
                                                                <p>Assignees</p>
                                                                <div fs-cmsnest-collection="assignees" class="assignees-wrap"></div>
                                                            </div>
                                                            <div class="border m-12"></div>
                                                            <div class="dates-wrap">
                                                                <div class="date">
                                                                    <p class="p-11 mb-10">Last Update</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark">{dateFormat(fieldName.lastUpdated)}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="date-copy">
                                                                    <p class="p-11 mb-10">Deadline Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        </Link>
                                                    </div>
                                                ))}
                                                </div>
:
                                                <div class="empty-state w-dyn-empty">
                                                    <p>This Group is Currently Empty</p>
                                                </div>
}
                                            </div>
                                            <div class="fs-labels w-dyn-list">
                                                <div fs-cmsnest-collection="categories" role="list" class="labels-wrap w-dyn-items">
                                                    <div role="listitem" class="w-dyn-item">
                                                        <div class="label">
                                                            <div class="label-bg"></div>
                                                            <p class="label-p"></p>
                                                            <a href="#" class="none w-inline-block"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-dyn-empty">
                                                    <div>No items found.</div>
                                                </div>
                                            </div>
                                            <div class="fs-assignees w-dyn-list">
                                                <div fs-cmsnest-collection="assignees" role="list" class="assignees-wrap w-dyn-items">
                                                    <div role="listitem" class="w-dyn-item">
                                                        <div class="assignee">
                                                            <img loading="lazy" src="" alt="" class="assignee-image"/>
                                                            <p class="none"></p>
                                                            <a href="#" class="none w-inline-block"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-dyn-empty">
                                                    <div>No items found.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="projects-column-copy">
                                        <div class="projects-count__wrap">
                                            <h2 class="h-16 text-dark">Active Projects</h2>
                                            <div class="count-box">
                                                <p class="active-number">3</p>
                                            </div>
                                        </div>
                                        <div class="scroll-wrap">
                                            <div class="projects-collection w-dyn-list">
                                                <div fs-cmsnest-element="list" role="list" class="collection-list filter-complex w-dyn-items">
                                                    <div role="listitem" class="active w-dyn-item">
                                                        <a href="#" class="project w-inline-block">
                                                            <div class="project-heading">
                                                                {/* <div class="project-logo__wrap">
                                                                    <img loading="lazy" src="" alt=""/>
                                                                </div> */}
                                                                <div>
                                                                    <h3 class="h-16 text-dark mb-5"></h3>
                                                                    <div class="number-wrap">
                                                                        <p>#</p>
                                                                        <p></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fs-cmsnest-collection="categories" class="cateogires-wrap"></div>
                                                            <div class="border m-12"></div>
                                                            <div class="assignees">
                                                                <p>Assignees</p>
                                                                <div fs-cmsnest-collection="assignees" class="assignees-wrap"></div>
                                                            </div>
                                                            <div class="border m-12"></div>
                                                            <div class="dates-wrap">
                                                                <div class="date">
                                                                    <p class="p-11 mb-10">Starting Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>  
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                                <div class="date">  
                                                                    <p class="p-11 mb-10">Deadline Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                            </div>      
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="empty-state w-dyn-empty">
                                                    <p>This Group is Currently Empty</p>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="projects-column-copy">
                                        <div class="projects-count__wrap">
                                            <h2 class="h-16 text-dark">Waiting Feedback/Approval</h2>
                                            <div class="count-box">
                                                <p class="approval-number">3</p>
                                            </div>
                                        </div>
                                        <div class="scroll-wrap">
                                            <div class="projects-collection w-dyn-list">
                                                <div fs-cmsnest-element="list" role="list" class="projects-list w-dyn-items">
                                                    <div role="listitem" class="approval w-dyn-item">
                                                        <a href="#" class="project w-inline-block">
                                                            <div class="project-heading">
                                                                <div class="project-logo__wrap">
                                                                    <img loading="lazy" src="" alt=""/>
                                                                </div>
                                                                <div>
                                                                    <h3 class="h-16 text-dark mb-5"></h3>
                                                                    <div class="number-wrap">
                                                                        <p>#</p>
                                                                        <p></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fs-cmsnest-collection="categories" class="cateogires-wrap"></div>
                                                            <div class="border m-12"></div>
                                                            <div class="assignees">
                                                                <p>Assignees</p>
                                                                <div fs-cmsnest-collection="assignees" class="assignees-wrap"></div>
                                                            </div>
                                                            <div class="border m-12"></div>
                                                            <div class="dates-wrap">
                                                                <div class="date">
                                                                    <p class="p-11 mb-10">Starting Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                                <div class="date">
                                                                    <p class="p-11 mb-10">Deadline Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="empty-state w-dyn-empty">
                                                    <p>This Group is Currently Empty</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="projects-column-copy">
                                        <div class="projects-count__wrap">
                                            <h2 class="h-16 text-dark">Completed Projects</h2>
                                            <div class="count-box">
                                                <p class="completed-number">3</p>
                                            </div>
                                        </div>
                                        <div class="scroll-wrap">
                                            <div class="projects-collection w-dyn-list">
                                                <div fs-cmsnest-element="list" role="list" class="projects-list w-dyn-items">
                                                    <div role="listitem" class="completed w-dyn-item">
                                                        <a href="#" class="project w-inline-block">
                                                            <div class="project-heading">
                                                                <div class="project-logo__wrap">
                                                                    <img loading="lazy" src="" alt=""/>
                                                                </div>
                                                                <div>
                                                                    <h3 class="h-16 text-dark mb-5"></h3>
                                                                    <div class="number-wrap">
                                                                        <p>#</p>
                                                                        <p></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fs-cmsnest-collection="categories" class="cateogires-wrap"></div>
                                                            <div class="border m-12"></div>
                                                            <div class="assignees">
                                                                <p>Assignees</p>
                                                                <div fs-cmsnest-collection="assignees" class="assignees-wrap"></div>
                                                            </div>
                                                            <div class="border m-12"></div>
                                                            <div class="dates-wrap">
                                                                <div class="date">
                                                                    <p class="p-11 mb-10">Starting Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                                <div class="date">
                                                                    <p class="p-11 mb-10">Deadline Date</p>
                                                                    <div class="date-wrapper">
                                                                        <div class="centered w-embed">
                                                                            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.35" d="M15 17.4999H5C3.61917 17.4999 2.5 16.3808 2.5 14.9999V5.83325H17.5V14.9999C17.5 16.3808 16.3808 17.4999 15 17.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 2.5H5C3.61917 2.5 2.5 3.61917 2.5 5V5.83333H17.5V5C17.5 3.61917 16.3808 2.5 15 2.5Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 15.8334C12.1269 15.8334 12.5 15.4603 12.5 15.0001C12.5 14.5398 12.1269 14.1667 11.6667 14.1667C11.2065 14.1667 10.8334 14.5398 10.8334 15.0001C10.8334 15.4603 11.2065 15.8334 11.6667 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 15.8334C8.79357 15.8334 9.16667 15.4603 9.16667 15.0001C9.16667 14.5398 8.79357 14.1667 8.33333 14.1667C7.8731 14.1667 7.5 14.5398 7.5 15.0001C7.5 15.4603 7.8731 15.8334 8.33333 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 15.8334C5.4602 15.8334 5.83329 15.4603 5.83329 15.0001C5.83329 14.5398 5.4602 14.1667 4.99996 14.1667C4.53972 14.1667 4.16663 14.5398 4.16663 15.0001C4.16663 15.4603 4.53972 15.8334 4.99996 15.8334Z" fill="currentColor"></path>
                                                                                <path d="M4.99996 12.4999C5.4602 12.4999 5.83329 12.1268 5.83329 11.6666C5.83329 11.2063 5.4602 10.8333 4.99996 10.8333C4.53972 10.8333 4.16663 11.2063 4.16663 11.6666C4.16663 12.1268 4.53972 12.4999 4.99996 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 12.4999C8.79357 12.4999 9.16667 12.1268 9.16667 11.6666C9.16667 11.2063 8.79357 10.8333 8.33333 10.8333C7.8731 10.8333 7.5 11.2063 7.5 11.6666C7.5 12.1268 7.8731 12.4999 8.33333 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 12.4999C12.1269 12.4999 12.5 12.1268 12.5 11.6666C12.5 11.2063 12.1269 10.8333 11.6667 10.8333C11.2065 10.8333 10.8334 11.2063 10.8334 11.6666C10.8334 12.1268 11.2065 12.4999 11.6667 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 12.4999C15.4602 12.4999 15.8333 12.1268 15.8333 11.6666C15.8333 11.2063 15.4602 10.8333 15 10.8333C14.5397 10.8333 14.1666 11.2063 14.1666 11.6666C14.1666 12.1268 14.5397 12.4999 15 12.4999Z" fill="currentColor"></path>
                                                                                <path d="M15 9.16667C15.4602 9.16667 15.8333 8.79357 15.8333 8.33333C15.8333 7.8731 15.4602 7.5 15 7.5C14.5397 7.5 14.1666 7.8731 14.1666 8.33333C14.1666 8.79357 14.5397 9.16667 15 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M11.6667 9.16667C12.1269 9.16667 12.5 8.79357 12.5 8.33333C12.5 7.8731 12.1269 7.5 11.6667 7.5C11.2065 7.5 10.8334 7.8731 10.8334 8.33333C10.8334 8.79357 11.2065 9.16667 11.6667 9.16667Z" fill="currentColor"></path>
                                                                                <path d="M8.33333 9.16667C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333C9.16667 7.8731 8.79357 7.5 8.33333 7.5C7.8731 7.5 7.5 7.8731 7.5 8.33333C7.5 8.79357 7.8731 9.16667 8.33333 9.16667Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <p class="text-dark"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="empty-state w-dyn-empty">
                                                    <p>This Group is Currently Empty</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="none w-embed">
            <style>
            .menu-left .hamburger-wrap.x{
            display: none;
        }
        </style>
            </div> */}
        </>
    )
} 

export default HomePage