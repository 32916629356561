// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDId62x0lnms9x1fMFTgXha-72Xcw0_jSI",
  authDomain: "kessel-construction.firebaseapp.com",
  projectId: "kessel-construction",
  storageBucket: "kessel-construction.appspot.com",
  messagingSenderId: "1055003770857",
  appId: "1:1055003770857:web:f2035ff69c200b6ab05578"
};

export const projectName = "Kessel Construction CMS"

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);