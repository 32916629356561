import { createReducer } from 'redux-act';

import { MOBILEMENU_SET_STATE } from '../../actions/mobileMenu';

const initialState = {
  open: false
};

export const mobileMenuReducer = createReducer(
  {
    [MOBILEMENU_SET_STATE]: (state, payload) => ({
      ...state,
      open: payload
    })
  },
  initialState
);
