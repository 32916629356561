import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc, collection, getDocs, query, where, serverTimestamp  } from 'firebase/firestore';
import { toastr } from "react-redux-toastr";
import { ChevronLeft } from 'react-feather';
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/Dots.css";

import { db, storage } from '../../firebase'
import { capitalizeFirstLetter, dateFormat } from "../../services"
import './styles.css'
import { setMobileMenu } from "../../state/actions/mobileMenu";

function DynamicItemPage () {
    
    const [image, setImage] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const [editedData, setEditedData] = useState(null);
    const [newImages, setNewImages] = useState({});
    const [imagesToDelete, setImagesToDelete] = useState([]);

    const dispatch = useDispatch()
    const openViewer = (i) => {
        setImage(i)
        setIsOpen(true);
        document.body.style.overflow = 'hidden'; // Disable scrolling
    };
  
    const closeViewer = () => {
        setImage(null)
        setIsOpen(false);
        document.body.style.overflow = 'auto'; // Enable scrolling
    };
  
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeViewer();
        }
    };

    const { parentCollectionName, id } = useParams();
    const state = useSelector((state) => (state))
    const collectionData = state.collections.collections[parentCollectionName];
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() =>{
        if (collectionData) {
            return setEditedData(collectionData.find((item) => item.id === id))
        }
        return null;
    }, [collectionData, id])

    const handleInputChange = (e, fieldName) => {
        const newData = { ...editedData };
        newData[fieldName].data = e.target.value;
        setEditedData(newData);
    };
    
    const handleDateChange = (e, fieldName) => {
        const newData = { ...editedData };
        newData[fieldName].data = e.target.valueAsDate.toISOString();
        setEditedData(newData);
    };
    
    const handleImageUpload = (e, fieldName) => {
        const file = e.target.files[0];
        const newImage = { file, url: URL.createObjectURL(file) };
        const newImagesData = { ...newImages };
        newImagesData[fieldName] = newImage;
        setNewImages(newImagesData);
    };

    const handleDeleteImage = (fieldName, fileName) => {
        console.log(fieldName,fileName)
        const newData = { ...editedData };
        newData[fieldName].data = {};
        setEditedData(newData);
      
        // Mark the image for deletion in the imagesToDelete state
        setImagesToDelete((prevImagesToDelete) => [...prevImagesToDelete, {fileName,fieldName}]);
    };
    
    const handleArrayImageUpload = (e, fieldName) => {
        const file = e.target.files[0];
        const newImage = { file, url: URL.createObjectURL(file) };
        const newImagesData = { ...newImages };
        if (!newImagesData[fieldName]) {
            newImagesData[fieldName] = [];
        }
        newImagesData[fieldName].push(newImage);
        setNewImages(newImagesData);
    };
    
    const handleArrayImageDelete = (fieldName, index, fileName) => {
        const newData = { ...editedData };
        const fieldData = newData[fieldName].data;
        console.log("handled delete")
        if (Array.isArray(fieldData)) {
          fieldData.splice(index, 1);
          setEditedData(newData);
        }
        setImagesToDelete((prevImagesToDelete) => [...prevImagesToDelete, {fileName,fieldName}]);
      };

    const handleArrayImageMove = (fieldName, index, direction) => {
        const newData = { ...editedData };
        const images = newData[fieldName].data;
        const newIndex = index + direction;
        if (newIndex >= 0 && newIndex < images.length) {
          [images[index], images[newIndex]] = [images[newIndex], images[index]];
          setEditedData(newData);
        }
      };

    const handleSave = async () => {
        // Upload new images to Firebase Storage
        try{
            const storageRefs = [];
            setIsLoading(true)
            for (const [fieldName, imageData] of Object.entries(newImages)) {
                if (Array.isArray(imageData)) {
                    const fieldImages = [];
                    const existingImages = editedData[fieldName].data;
            
                    for (const image of imageData) {
                        const storageRef = ref(
                            storage,
                            `${parentCollectionName}/${id}/${fieldName}/${image.file.name}`
                        );
                        await uploadBytes(storageRef, image.file);
                        storageRefs.push([fieldName, storageRef, image.file.name]);
                
                        const downloadURL = await getDownloadURL(storageRef);
                        fieldImages.push({ url: downloadURL, filename: image.file.name });
                    }
            
                    // Update existing images order
                    for (const existingImage of existingImages) {
                        if (!fieldImages.find((image) => image.filename === existingImage.filename)) {
                            fieldImages.push(existingImage);
                        }
                    }
            
                    const newData = { ...editedData };
                    newData[fieldName].data = fieldImages;
                    setEditedData(newData);
                } else {
                    const storageRef = ref(
                    storage,
                    `${parentCollectionName}/${id}/${fieldName}/${imageData.file.name}`
                    );
                    await uploadBytes(storageRef, imageData.file);
                    storageRefs.push([fieldName, storageRef, imageData.file.name]);
            
                    const newData = { ...editedData };
                    const downloadURL = await getDownloadURL(storageRef);
                    newData[fieldName].data = { url: downloadURL, filename: imageData.file.name };
                    setEditedData(newData);
                }
            }

            // Delete images marked for deletion from Firebase Storage (single Image)
            for (const {fileName, fieldName} of imagesToDelete) {
                const storageRef = ref(storage, `${parentCollectionName}/${id}/${fieldName}/${fileName}`);
                await deleteObject(storageRef);
            }
            
            // Update item data in Firestore
            const currentTimestamp = serverTimestamp();
            const newData = { ...editedData, lastUpdated: currentTimestamp };
            await updateDoc(doc(collection(db, parentCollectionName), id), newData);

            // Find the document in the "index" collection where the field name matches parentCollectionName
            const indexCollectionRef = collection(db, "index");
            const indexQuery = query(indexCollectionRef, where("name", "==", parentCollectionName));
            const indexSnapshot = await getDocs(indexQuery);
            
            // Update the lastUpdated field in the found document with the current timestamp
            if (!indexSnapshot.empty) {
                const indexDocRef = indexSnapshot.docs[0].ref;
                await updateDoc(indexDocRef, { lastUpdated: currentTimestamp });
            }
            
            // Reset newImages state to null after saving
            setNewImages({}); 

            // Clear the imagesToDelete state
            setImagesToDelete([]);

            setIsLoading(false)
            toastr.success("Item updated succesfully","notification");
            // TODO: show success message, redirect, etc.
        } catch (error) {
            console.error(error);
            toastr.error("An error occurred", error); // Error notification
        }
    };

    
    if (!editedData) {
        return <div>Loading...</div>;
    }
    

    return (
        <>
            <div class="menu-projects">
                {isOpen && (
                    <div
                    className="image-overlay"
                    onClick={handleOverlayClick}
                    >
                    <div className="image-container">
                        <span className="close-button" onClick={closeViewer}>
                        &times;
                        </span>
                        <img src={image} alt="Large Image" className="large-image" />
                    </div>
                    </div>
                )}
                <div class="menu-projects__wrap">
                    <div class="projects__heading">
                        <div class="heading-center">
                            <div class="projects-head__flex">
                            <h1 class="h-24 text-dark">{capitalizeFirstLetter(editedData.name.data)} Item</h1>
                            </div>
                            <div class="update-flex">
                                <Link to={`/${parentCollectionName}`} className="link-without-decoration">
                                    <ChevronLeft color="#080045"/>
                                    <p className="link-text">{capitalizeFirstLetter(parentCollectionName)} collection</p>
                                </Link>
                                <div class="dot"></div>
                                <p>Last updated</p>
                                <div class="w-dyn-list">
                                    <div role="list" class="w-dyn-items">
                                        <div role="listitem" class="w-dyn-item">
                                            <p>{dateFormat(editedData.lastUpdated)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=> dispatch(setMobileMenu(true))} data-w-id="ecbbd13b-b482-7cdf-0270-fd4d2e7454e4" style={{opacity:1, display: "flex"}} class="hamburger-wrap _3-lines">
                            <div class="hamburger-line"></div>
                            <div class="hamburger-line middle"></div>
                            <div class="hamburger-line"></div>
                        </div>
                        </div>
                        <div class="border mt-16"></div>
                        <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs-home w-tabs">
                        <div class="tabs-content-2 w-tab-content">
                            <div class="tab-pane-tab-2 w-tab-pane w--tab-active">
                                <div className="invoices-wrapper">
                                    <div key="name" class="form-field-wrap">
                                        <label class="form-label">name</label>
                                        <input
                                            class="form-field w-input"
                                            type="text"
                                            value={editedData.name.data}
                                            onChange={(e) => handleInputChange(e, 'name')}
                                        />
                                        <div class="spacer-xs"></div>
                                    </div>
                                    <div key="slug" class="form-field-wrap">
                                        <label class="form-label">slug</label>
                                        <input
                                            class="form-field w-input"
                                            type="text"
                                            value={editedData.slug.data}
                                            onChange={(e) => handleInputChange(e, 'slug')}
                                        />
                                        <div class="spacer-xs"></div>
                                    </div>
                                    {Object.entries(editedData).map(([fieldName, fieldData]) => {
                                        if (fieldName === 'id' || fieldName === 'lastUpdated' || fieldName === 'name' || fieldName === 'slug') {
                                        return null;
                                        } else if (fieldData.type === 'date') {
                                        return (
                                            <div key={fieldName} class="form-field-wrap">
                                                <label class="form-label">
                                                    {fieldName}:
                                                </label>
                                                <input
                                                    class="form-field w-input"
                                                    type="date"
                                                    value={(fieldData.data ? new Date(fieldData.data) : new Date()).toISOString().substr(0, 10)}
                                                    onChange={(e) => handleDateChange(e, fieldName)}
                                                />
                                                <div class="spacer-xs"></div>
                                            </div>
                                        );
                                        } else if (fieldData.type === 'string' || fieldData.type === 'number') {
                                        return (
                                            <div key={fieldName} class="form-field-wrap">
                                                <label class="form-label">
                                                    {fieldName}:
                                                </label>
                                                <input
                                                    class="form-field w-input"
                                                    type="text"
                                                    value={fieldData.data}
                                                    onChange={(e) => handleInputChange(e, fieldName)}
                                                />
                                                <div class="spacer-xs"></div>
                                            </div>
                                        );
                                        } else if (fieldData.type === 'image') {
                                        return (
                                            <div key={fieldName} class="form-field-wrap">
                                                <label class="form-label" htmlFor={fieldName}>{fieldName}</label>
                                                <div class="div-block-copy">                                                    
                                                    {Object.keys(fieldData.data).length === 0 && (
                                                        <label className="file-input" htmlFor={fieldName}>
                                                            Choose file
                                                            <input
                                                                name={fieldName}
                                                                id={fieldName}
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => handleImageUpload(e, fieldName)}
                                                                />
                                                        </label>
                                                    )}
                                                    {Object.keys(fieldData.data).length !== 0 && !newImages[fieldName] && (
                                                        <>
                                                            <div>{fieldData.data.filename}</div>
                                                            <div class="button-wrapper">
                                                                <div class="view-btn w-button" onClick={() => openViewer(fieldData.data.url)}>
                                                                    <img src={fieldData.data.url} alt={fieldData.data.filename} className="preview-image" />
                                                                    <div class="icon-wrapper">
                                                                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="12" cy="12" r="3" stroke="#33363F" stroke-width="2"/>
                                                                            <path d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z" stroke="#33363F" stroke-width="2"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div class="delete-btn" onClick={() => handleDeleteImage(fieldName, fieldData.data.filename)}>
                                                                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M14 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M4 7H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {newImages[fieldName] && (
                                                        <>
                                                            <div>{newImages[fieldName].file.name}</div>
                                                            <div class="button-wrapper">
                                                                <div class="view-btn w-button" onClick={() => openViewer(newImages[fieldName].url)}>
                                                                    <img src={newImages[fieldName].url} alt={newImages[fieldName].file.name} className="preview-image" />
                                                                    <div class="icon-wrapper">
                                                                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="12" cy="12" r="3" stroke="#33363F" stroke-width="2"/>
                                                                            <path d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z" stroke="#33363F" stroke-width="2"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div 
                                                                    class="delete-btn"
                                                                    onClick={() => {
                                                                        const newImagesData = { ...newImages };
                                                                        newImagesData[fieldName] = null;
                                                                        setNewImages(newImagesData);
                                                                    }}
                                                                >
                                                                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M14 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M4 7H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div class="spacer-xs"></div>
                                            </div>
                                        );
                                        } else if (fieldData.type === 'arrayOfImages') {
                                        return (
                                            <>
                                            <div key={fieldName}>
                                                <label class="form-label">{fieldName}</label>
                                                {fieldData.data.map((imageData, index) => (
                                                    <div key={index} class="array-items-wrapper">
                                                        <div>{imageData.filename}</div>
                                                        <button
                                                            type="button"
                                                            disabled={index === 0}
                                                            onClick={() => handleArrayImageMove(fieldName, index, -1)}
                                                        >
                                                            Move up
                                                        </button>
                                                        <button
                                                            type="button"
                                                            disabled={index === fieldData.data.length - 1}
                                                            onClick={() => handleArrayImageMove(fieldName, index, 1)}
                                                        >
                                                            Move down
                                                        </button>
                                                        <div class="button-wrapper">
                                                            <div class="view-btn w-button" onClick={() => openViewer(imageData.url)}>
                                                                <img src={imageData.url} alt={imageData.filename} className="preview-image" />
                                                                <div class="icon-wrapper">
                                                                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="12" cy="12" r="3" stroke="#33363F" stroke-width="2"/>
                                                                        <path d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z" stroke="#33363F" stroke-width="2"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div class="delete-btn" onClick={() => handleArrayImageDelete(fieldName, index, imageData.filename)}>
                                                                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M14 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M4 7H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {newImages[fieldName] && (
                                                    <div style={{width:"100%", display:"flex", alignItems: "center", justifyContent:"center"}}>
                                                        <div>Unsaved additions</div>
                                                    </div>
                                                )}
                                                {newImages[fieldName] &&
                                                    newImages[fieldName].map((newImageData, index) => (
                                                        <div key={index} class="array-items-wrapper">
                                                        <div>{newImageData.file.name}</div>
                                                        <button
                                                            type="button"
                                                            disabled={index === 0}
                                                            onClick={() => {
                                                                const newImagesData = { ...newImages };
                                                                [
                                                                newImagesData[fieldName][index - 1],
                                                                newImagesData[fieldName][index],
                                                                ] = [
                                                                newImagesData[fieldName][index],
                                                                newImagesData[fieldName][index - 1],
                                                                ];
                                                                setNewImages(newImagesData);
                                                            }}
                                                        >
                                                            Move up
                                                        </button>
                                                        <button
                                                            type="button"
                                                            disabled={index === newImages[fieldName].length - 1}
                                                            onClick={() => {
                                                                const newImagesData = { ...newImages };
                                                                [
                                                                newImagesData[fieldName][index],
                                                                newImagesData[fieldName][index + 1],
                                                                ] = [
                                                                newImagesData[fieldName][index + 1],
                                                                newImagesData[fieldName][index],
                                                                ];
                                                                setNewImages(newImagesData);
                                                            }}
                                                        >
                                                            Move down
                                                        </button>
                                                            <div class="button-wrapper">
                                                                <div class="view-btn w-button" onClick={() => openViewer(newImageData.url)}>
                                                                    <img src={newImageData.url} alt={newImageData.filename} className="preview-image" />
                                                                    <div class="icon-wrapper">
                                                                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="12" cy="12" r="3" stroke="#33363F" stroke-width="2"/>
                                                                            <path d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z" stroke="#33363F" stroke-width="2"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div 
                                                                    class="delete-btn" 
                                                                    onClick={() => {
                                                                        const newImagesData = { ...newImages };
                                                                        newImagesData[fieldName].splice(index, 1);
                                                                        setNewImages(newImagesData);
                                                                    }}
                                                                >
                                                                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M14 11V17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M4 7H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <label className="file-input flex-start" style={{display:'inline-block'}} htmlFor={fieldName}>
                                                    Add image
                                                    <input
                                                        name={fieldName}
                                                        id={fieldName}
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleArrayImageUpload(e, fieldName)}
                                                    />
                                                </label>
                                            </div>
                                            <div class="spacer-xs"></div>
                                            </>
                                        );
                                        } else {
                                        return null; // unsupported field type
                                        }
                                    })}
                                    <button
                                        class="submit-button w-button"
                                        onClick={handleSave}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <Dots /> : <>Save</>}
                                    </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="none w-embed">
            <style>
            .menu-left .hamburger-wrap.x{
            display: none;
        }
        </style>
            </div> */}
        </>
    )
} 

export default DynamicItemPage