import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { storage } from '../../firebase';

function Stats() {
    const location = useLocation();
    const currentPage = location.pathname;
    console.log("currentPage", currentPage)
    const [storageUsed, setStorageUsed] = useState(0);

    useEffect(() => {
        // Function to fetch storage usage data
        const getStorageUsage = async () => {
            try {
                const storageRef = storage.ref();
                const metadata = await storageRef.getMetadata();
                const { size } = metadata / (1024 * 1024 * 1024); // Convert bytes to GB
                setStorageUsed(size.toFixed(1)); // Round to 1 decimal place
            } catch (error) {
                console.error('Error fetching storage usage:', error);
            }
        };

        getStorageUsage();
    }, []);

    return (

        <div class="menu-right">
            <div class="menu-r__wrap">
                <div class="menu-r__header">
                    <div class="stats-flex">
                        <h2 class="h-24 text-dark stats">Stats</h2>
                    </div>
                    <div data-w-id="9340c45e-d5f2-fa85-4bbf-3e6371e71439" class="hamburger-wrap x">
                        <div class="hamburger-line top"></div>
                        <div class="hamburger-line bottom"></div>
                    </div>
                </div>
                <p class="text-dark mb-24">Statistics</p>
                <div class="stats-grid">
                    <div id="w-node-ddcce60c-7d28-6fd3-2508-07df49ebf169-49ebf160" class="stats-grid__item">
                        <p>Storage <br/> Used</p>
                        <div class="stats-grid__flex">
                            <p class="active-number stats-number">{storageUsed} GB</p>
                            <div class="small-circle"></div>
                        </div>
                    </div>
                    <div id="w-node-ddcce60c-7d28-6fd3-2508-07df49ebf170-49ebf160" class="stats-grid__item">
                        <p>Storage Available</p>
                        <div class="stats-grid__flex">
                            <p class="upcoming-number stats-number">5 GB</p>
                            <div class="small-circle orange"></div>
                        </div>
                    </div>
                    {/* <div id="w-node-ddcce60c-7d28-6fd3-2508-07df49ebf177-49ebf160" class="stats-grid__item">
                        <p>Calls</p>
                        <div class="stats-grid__flex">
                            <p class="completed-number stats-number">22</p>
                            <div class="small-circle blue"></div>
                        </div>
                    </div>
                    <div id="w-node-ddcce60c-7d28-6fd3-2508-07df49ebf17e-49ebf160" class="stats-grid__item">
                        <p>null</p>
                        <div class="stats-grid__flex">
                            <p class="approval-number stats-number">1</p>
                            <div class="small-circle pink"></div>
                        </div>
                    </div>
                    <div id="w-node-ddcce60c-7d28-6fd3-2508-07df49ebf185-49ebf160" class="stats-grid__item-copy">
                        <p>Total</p>
                        <div class="stats-grid__flex">
                            <p class="total-number stats-number">30</p>
                            <div class="small-circle gray"></div>
                        </div>
                    </div> */}
                </div>
                <div class="members-wrap">
                    <p class="text-dark mb-24">Team Members</p>
                    <div class="members-collection w-dyn-list">
                        <div role="list" class="members-list w-dyn-items">
                            <div role="listitem" class="w-dyn-item">
                                <div class="member-info">
                                    <img src="" loading="lazy" alt="" class="member-img" />
                                    <p class="member-name"></p>
                                </div>
                            </div>
                        </div>
                        <div class="w-dyn-empty">
                            <div>No items found.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats;