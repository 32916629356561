import { createAction } from "redux-act";
import { collection, onSnapshot } from 'firebase/firestore';

import { db } from "../../firebase";

export const UPDATE_COLLECTION = createAction('UPDATE_COLLECTION');
export const SAVE_UNSUBSCRIBE = createAction('SAVE_UNSUBSCRIBE');
export const CLEAR_COLLECTIONS = createAction('CLEAR_COLLECTIONS');

export const fetchDocuments = async (dispatch) => {
  // Set up the listener for the index collection
  const indexRef = collection(db, 'index');
  const unsubscribeIndex = onSnapshot(indexRef, async (indexSnapshot) => {
    // Process the index collection
    const indexData = [];
    indexSnapshot.forEach((doc) => {
      const indexItem = {
        id: doc.id,
        collectionName: doc.data().name,
        fields: doc.data().fields, // Assuming the fields are stored in an array in the 'fields' field
        lastUpdated: doc.data().lastUpdated,
        filterType: doc.data().filterType
      };
      indexData.push(indexItem);
    });

    // Dispatch the index data
    dispatch({
      type: 'UPDATE_COLLECTION',
      payload: { collectionName: 'index', data: indexData },
    });

    // Set up listeners for other collections
    const unsubscribeListeners = [];
    indexData.forEach((indexItem) => {
      const collectionRef = collection(db, indexItem.collectionName);
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
          type: 'UPDATE_COLLECTION',
          payload: { collectionName: indexItem.collectionName, data },
        });
      });

      // Save the unsubscribe function to stop the listener when necessary
      unsubscribeListeners.push({ collectionName: indexItem.collectionName, unsubscribe });
    });

    // Save the index collection's unsubscribe function to stop its listener when necessary
    dispatch({
      type: 'SAVE_UNSUBSCRIBE',
      payload: { collectionName: 'index', unsubscribe: unsubscribeIndex },
    });

    // Save all other collection listeners' unsubscribe functions
    unsubscribeListeners.forEach((listener) => {
      dispatch({
        type: 'SAVE_UNSUBSCRIBE',
        payload: { collectionName: listener.collectionName, unsubscribe: listener.unsubscribe },
      });
    });
  });
};