import { useDispatch } from "react-redux"
import { useState } from "react"
import { Link } from "react-router-dom"
import { collection, addDoc, setDoc, serverTimestamp  } from 'firebase/firestore';
import { toastr } from "react-redux-toastr";
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/Dots.css";

import { db } from '../../firebase'
import { capitalizeFirstLetter } from "../../services"
import './styles.css'
import { setMobileMenu } from "../../state/actions/mobileMenu";

function NewCollectionPage () {
    const [isLoading, setIsLoading] = useState(false);
    const [collectionName, setCollectionName] = useState('');
    const [fields, setFields] = useState({
        name: { name: 'Name', type: 'string', data: '' },
        slug: { name: 'Slug', type: 'string', data: '' },
    });
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('string');
    console.log(fields)
    const dispatch = useDispatch();

    const handleAddField = () => {
        if (fieldName && fieldType) {
        const newField = {
            name: fieldName,
            type: fieldType,
            data: fieldType === 'arrayOfImages' ? [] : '',
        };
        setFields((prevFields) => ({
            ...prevFields,
            [fieldName.replace(/\s+/g, '-').toLowerCase()]: newField,
        }));
        setFieldName('');
        setFieldType('string');
        }
    };

    const handleEditField = (fieldId, fieldData, fieldType) => {
        const editedField = {
        ...fields[fieldId],
        [fieldType]: fieldData,
        };
        setFields((prevFields) => ({
        ...prevFields,
        [fieldId]: editedField,
        }));
        setFieldName('');
        setFieldType('string');
    };

    const handleDeleteField = (fieldId) => {
        // Prevent deleting 'name' and 'slug' fields
        if (fieldId !== 'name' && fieldId !== 'slug') {
        const newFields = { ...fields };
        delete newFields[fieldId];
        setFields(newFields);
        }
        
    };

    const handleCreateCollection = async () => {
        try {
            setIsLoading(true)
            if(collectionName === ""){
                alert("Please name the collection");
                setIsLoading(false)
                return
            }
             // Update item data in Firestore
            const currentTimestamp = serverTimestamp();
             
            // Create a new document under the 'index' collection
            const newIndexDocRef = await addDoc(collection(db, 'index'), {
                name: collectionName,
                fields: fields,
                lastUpdated: currentTimestamp,
            });

            // Optionally, you can dispatch an action to update your Redux state with the new collection
            dispatch({
                type: 'UPDATE_COLLECTION',
                payload: {
                    collectionName,
                    data: [],
                },
            });

            const newCollectionName = collectionName.toLowerCase(); // Convert to lowercase (for simplicity)
            await setDoc(collection(db, newCollectionName), {}); // Empty document to create an empty collection


            setIsLoading(false)
            console.log('Collection created successfully with ID:', newIndexDocRef.id);
            toastr.success("Collection created successfully with ID:", newIndexDocRef.id)
        } catch (error) {
            setIsLoading(false)
            console.error('Error creating collection:', error);
        }
    };
  
    return (
        <>
            <div class="menu-projects">
                <div class="menu-projects__wrap">
                    <div class="projects__heading">
                        <div class="heading-center">
                            <div class="projects-head__flex">
                            <h1 class="h-24 text-dark">{collectionName? capitalizeFirstLetter(collectionName):"New Collection"} </h1>
                            </div>
                            <div class="update-flex">
                                <Link to={`/`} className="link-without-decoration">
                                    <p className="link-text">Home</p>
                                </Link>
                            </div>
                        </div>
                        <div onClick={()=> dispatch(setMobileMenu(true))} data-w-id="ecbbd13b-b482-7cdf-0270-fd4d2e7454e4" style={{opacity:1, display: "flex"}} class="hamburger-wrap _3-lines">
                            <div class="hamburger-line"></div>
                            <div class="hamburger-line middle"></div>
                            <div class="hamburger-line"></div>
                        </div>
                        </div>
                        <div class="border mt-16"></div>
                        <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs-home w-tabs">
                        <div class="tabs-content-2 w-tab-content">
                            <div class="tab-pane-tab-2 w-tab-pane w--tab-active">
                                <div className="invoices-wrapper">
                                    <div>
                                        <h2>Create a new collection</h2>
                                        <div>
                                            <label>Collection Name</label>
                                            <input
                                            type="text"
                                            value={collectionName}
                                            onChange={(e) => setCollectionName(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <h3>Add Fields</h3>
                                            <div>
                                            <label>Field Name</label>
                                            <input
                                                type="text"
                                                value={fieldName}
                                                onChange={(e) => setFieldName(e.target.value)}
                                            />
                                            </div>
                                            <div>
                                            <label>Field Type</label>
                                            <select value={fieldType} onChange={(e) => setFieldType(e.target.value)}>
                                                <option value="string">String</option>
                                                <option value="number">Number</option>
                                                <option value="date">Date</option>
                                                <option value="image">Image</option>
                                                <option value="arrayOfImages">Array Of Images</option>
                                            </select>
                                            </div>
                                            <button onClick={handleAddField}>Add Field</button>
                                        </div>
                                        <div>
                                            <h3>Fields</h3>
                                            {Object.entries(fields).map(([fieldId, field]) => (
                                                    <div class="form-wrap" li key={fieldId}>
                                                        <div class="form-flex password">
                                                            <div class="form-field-wrap">
                                                                <label for="name-3" class="form-label">Field Name</label>
                                                                <input 
                                                                    disabled={field.id === 'name' || field.id === 'slug'}
                                                                    type="text" 
                                                                    class="form-field w-input"
                                                                    value={field.name}
                                                                    onChange={(e) => handleEditField(fieldId, e.target.value, "name")}
                                                                />
                                                            </div>
                                                            <div class="form-field-wrap">
                                                                <label class="form-label">Field Type</label>
                                                                <select 
                                                                    disabled={field.id === 'name' || field.id === 'slug'}
                                                                    value={field.type} 
                                                                    onChange={(e) => handleEditField(fieldId, e.target.value, "type")}
                                                                    class="form-field w-input"
                                                                >
                                                                    <option value="string">String</option>
                                                                    <option value="number">Number</option>
                                                                    <option value="date">Date</option>
                                                                    <option value="image">Image</option>
                                                                    <option value="arrayOfImages">Array Of Images</option>
                                                                </select>
                                                            </div>
                                                            <button onClick={() => handleDeleteField(fieldId)}>Delete</button>
                                                        </div>
                                                    </div>
                                            ))}
                                        </div>
                                    </div>
                                    <button
                                        class="submit-button w-button"
                                        onClick={handleCreateCollection}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <Dots /> : <>Create Collection</>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="none w-embed">
            <style>
            .menu-left .hamburger-wrap.x{
            display: none;
        }
        </style>
            </div> */}
        </>
    )
} 

export default NewCollectionPage